// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-section-five-js": () => import("./../src/pages/home/sectionFive.js" /* webpackChunkName: "component---src-pages-home-section-five-js" */),
  "component---src-pages-home-section-four-js": () => import("./../src/pages/home/sectionFour.js" /* webpackChunkName: "component---src-pages-home-section-four-js" */),
  "component---src-pages-home-section-one-js": () => import("./../src/pages/home/sectionOne.js" /* webpackChunkName: "component---src-pages-home-section-one-js" */),
  "component---src-pages-home-section-three-js": () => import("./../src/pages/home/sectionThree.js" /* webpackChunkName: "component---src-pages-home-section-three-js" */),
  "component---src-pages-home-section-two-js": () => import("./../src/pages/home/sectionTwo.js" /* webpackChunkName: "component---src-pages-home-section-two-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */)
}

